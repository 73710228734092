import React from "react";
import Header from "../Header";
import Bottom from "../Bottom/Bottom";
import { Link } from "react-router-dom";
import "./Contact.css";
import Whatsapp from "../Whatsapp/Whatsapp";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_qxirxep",
        "template_lr0ser8",
        form.current,
        "5dVd9-lstzllMgIrW"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  const notify = () =>
    toast.success("Message sent successfully", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  return (
    <>
      <div>
        <Header />
        <Whatsapp />

        <div className="home-hea">
          <br />
          <br />
          <br />
          <header
          //   class="page-header ltx-parallax"
          //   style={{ backgroundPosition: "50% -68px;", height: "450px" }}
          >
            <img
              class="page-header ltx-parallax"
              src="./contactimg1.jpg"
              alt=""
              style={{ height: "400px", width: "100%" }}
            />
            {/* <div className="about-nav">
      <span className="about-nav1">Home</span> {'>>'} <span>Contact us</span>
    </div> */}
          </header>
          <br />
          <div className="heading">
            <h1> Contact us</h1>
          </div>
          {/* <br /> */}
          {/* <br /> */}

          <div className="">
            <section class="contact-details ptb--60">
              <div class="container">
                <div class="row" id="row">
                  <div class="col-md-6">
                    {/* <h1 class="heading text-center">Contact Us</h1>  */}
                    <div class="col-md-12">
                      <div class="card">
                        <div class="icon">
                          <i class="fa fa-map-marker" aria-hidden="true"></i>
                        </div>
                        <div class="card-body">
                          <h1 class="card-title">Address</h1>
                          <p>
                            HSR Layout Sector 2, <br />
                            Bangalore 560102
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="card">
                            <div class="icon">
                              <i class="fa fa-phone" aria-hidden="true"></i>
                            </div>
                            <div class="card-body">
                              <h1 class="card-title">Contact</h1>
                              <span class="bottom-line">
                                {" "}
                                Mob - +91 7337796813{" "}
                              </span>
                              <br />
                              {/* <span class="bottom-line" > Tel - +91 080 22112330, <br /> +91 080 22112331</span> */}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="card">
                            <div class="icon">
                              <i class="fa fa-envelope" aria-hidden="true"></i>
                            </div>
                            <div class="card-body">
                              <h1 class="card-title">Email</h1>
                              <div class="bottom-line">info@skillfirst.in </div>
                              <div class="bottom-line">
                                skillfisrt.in@gmail.com{" "}
                              </div>

                              {/* <ul>
										<li>info@skillfirst.in</li>
										<li>skillfisrt.in@gmail.com </li>
									</ul> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <h1 class="heading text-center">Get in touch</h1>
                    <form ref={form} onSubmit={sendEmail}>
                      <div class="form-group">
                        <input
                          class="form-control"
                          name="user_name"
                          type="text"
                          placeholder="Your Name"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <input
                          class="form-control"
                          name="user_email"
                          type="text"
                          placeholder="E-mail"
                          required
                        />
                      </div>
					  <div class="form-group">
                        <input
                          class="form-control"
                          name="user_contact"
                          type="number"
                          placeholder="Mobile No"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <input
                          class="form-control"
                          name="subject"
                          type="text"
                          placeholder="Subject"
						  required
                        />
                      </div>

                      <div class="form-group">
                        <textarea
                          class="form-control"
                          cols="30"
                          rows="5"
                          name="message"
                          placeholder="Your Comments here.."
                          required
                        ></textarea>
                      </div>

                      <div class="form-group">
                        <button
                          type="submit"
                          class="btn btn-submit"
                          onClick={notify}
                        >
                          Submit
                        </button>
                        <ToastContainer
                          position="top-center"
                          autoClose={5000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          theme="light"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>

          {/* <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.112894270556!2d77.5949866140476!3d12.964627190860446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1534d743186f%3A0x942f9a2edeb0dac4!2sGlobe%20Express%20Travel%20Academy%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1670991323797!5m2!1sen!2sin"
            width="600"
            height="450"
            style={{ border: "0", width: "100%" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div> */}
        </div>

        <Bottom />
      </div>
    </>
  );
};

export default Contact;
