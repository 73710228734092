import logo from './logo.svg';
import './App.css';
import Home from './Component/Home/Home';
import Aboutus from './Component/Aboutus/Aboutus';
import Contact from './Component/Contact/Contact'
import Bottom from './Component/Bottom/Bottom'
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
  return (
    <>
   
  {/* <Header/> */}
     {/* <Home/> */}
    {/* <Aboutus /> */}
    {/* <Bottom/> */}

    <BrowserRouter>
      <Routes>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home/>} />
          <Route path="/about" element={<Aboutus/>} />
          <Route path="/contact" element={<Contact/>} />
      </Routes>
    </BrowserRouter>


    </>
  );
}

export default App;
