import React, { useEffect, useState } from "react";
import "./Bottom.css";
import { BsFacebook, BsInstagram, BsYoutube, BsGoogle } from "react-icons/bs";
import { Link } from "react-router-dom";


const Bottom = () => {
  return (
    <>
      <div className="head-cont">
        <div className="container ben-para">
          <div className="row">
            <div className="col-md-4">
              <h2 className="quick">Quick Links</h2>
              <ul className="li">
               <Link to="/home" style={{textDecoration:"none"}}><li>Home</li> </Link>
               <Link to="/about" style={{textDecoration:"none"}}><li>About Us</li></Link>
               <Link to="/contact" style={{textDecoration:"none"}}><li>Contact Us</li></Link>
              
    
              </ul>
            </div>

            <div className="col-md-4">
              <h2 className="cont">Contact Us</h2>
              <ul className="li">
                <li>HSR Layout Sector 2, </li>
                <li>Bangalore 560102</li>
                <li> info@skillfirst.in </li>
                <li>skillfisrt.in@gmail.com</li>
                <li>Mob: 7337796813</li>
           
               
              </ul>
            </div>

            <div className="col-md-4">
              <h2 className="cont1">Social Media</h2>
              
              <div className="a-icon1">
              <a className="a-icon"
                    href="https://www.facebook.com/profile.php?id=100089680635872">
                    <BsFacebook />
                  </a>


              <a className="a-icon" href="https://www.instagram.com/skillfirst.in/?igshid=ZDdkNTZiNTM%3D">
                  <BsInstagram />
                  </a>
                  </div>

              
            </div>
          </div>
        </div>
      </div>
      <div className="botom">
        © 2023 All Rights Reserved Terms of Use and Privacy Policy
      </div>
    </>
  );
};

export default Bottom;
