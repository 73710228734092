import React from "react";
import "../Home/Home.css";
import Card from "react-bootstrap/Card";
import img1 from "./images/main.jpg";
import img2 from "./images/img1.jpg";
import img3 from "./images/img3.jpg";
import img4 from "./images/img5.jpg";
import img5 from "./images/A.png";
import img6 from "./images/ayl.png";
import img7 from "./images/boot.png";
import img8 from "./images/c.png";
import img9 from "./images/c++.png";
import img10 from "./images/cp.png";
import img11 from "./images/django.png";
import img12 from "./images/flask.png";
import img13 from "./images/html.png";
import img14 from "./images/java.png";
import img15 from "./images/jq.png";
import img16 from "./images/mysql.png";
import img17 from "./images/powerbi.jpg";
import img18 from "./images/py.jpg";
import card1 from "./images/card1.jpg";
import card2 from "./images/card2.jpg";
import card3 from "./images/card3.jpg";
import Header from "../Header";
import Bottom from "../Bottom/Bottom";
import Carousel from "react-bootstrap/Carousel";
import OwlCarousel from "react-owl-carousel";
import abtimg from "./images/istockphoto-1047549798-612x612.jpg";
import abtimg1 from "./images/istockphoto-1141304181-612x612.jpg";
import abtimg2 from "./images/istockphoto-1409835273-612x612.jpg";
import Whatsapp from "../Whatsapp/Whatsapp";

const Home = () => {
  //Owl Carousel Settings
  const options = {
    loop: true,
    center: true,
    items: 3,
    margin: 0,
    autoplay: true,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 3,
      },
      600: {
        items: 5,
      },
      1000: {
        items: 7,
      },
    },
  };
  return (
    <>
      <Header />

      <Whatsapp />

      <div>
        <br />
        <br />
        <br />
        <br />
        <img className="home-img-head" src="./home-edu-ban.png" alt="" />
        {/* <div
          className="img1"
          style={{ backgroundImage: "url('./home-edu-ban.png')" }}
        >
        </div> */}
      </div>

      {/* <div className="">
        <Carousel>
          <Carousel.Item>
            <div
              className="img1"
              style={{ backgroundImage: `url(${img1})` }}
            ></div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="img2"></div>
            <div
              className="img1"
              style={{ backgroundImage: `url(${img3})` }}
            ></div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="img3"></div>

            <div
              className="img1"
              style={{ backgroundImage: `url(${img2})` }}
            ></div>
          </Carousel.Item>
        </Carousel>
      </div> */}

      {/* <section className="aboutus">

        <div >
          <h3 className="about-heading">
            Skillfirst.in is a IT Training and Development Company catering to
            needs of individuals at various levels of their careers:
          </h3>
          <br />
          <br />
        </div>

        <div className="row">
          <img className="aboutimg" src={abtimg} alt="aboutimg" />
          <div className="about-text">
          <h4 className="about-h4">For Freshers</h4>
          <p className="home-ptext">
            Skillfirst.in is a IT training and development company focused on
            creating a manageable platform for any student to plan, discuss and
            take action for creating a career and learning path best suited to
            him or her.
          </p>
          <p className="home-ptext">
            At skillfirst.in we help students identify their strengths and hone
            relevant skills and knowledge in order to become employable as soon
            as possible.
          </p>
          </div>
        </div>
        <br />

        <div className="row">
          <img className="aboutimg" src={abtimg1} alt="aboutimg" />
          <div className="about-text">
            <h4 className="about-h4">For experienced professionals or job seekers</h4>
            <p className="home-ptext">
              We help the experienced professionals to quickly build the next
              set of skills and apply for newer roles thereby avoiding loss of
              interest in the current profile or loss of employment.
            </p>
          </div>
        </div>
        <br />

        <div className="row">
          <img className="aboutimg" src={abtimg2} alt="aboutimg" />
          <div className="about-text">
          <h4 className="about-h4">School students</h4>
          <p className="home-ptext">
            Many advanced topics are introduced right at the high-school or
            secondary school level. Students can join online IT training
            sessions and cover the school syllabus and also go that extra mile
            to become proficient in the software subject such as C++, JAVA, SQL
            etc.
          </p>
          </div>
        </div>
        <br />
      </section>
      <br /> */}

      {/* <div className="butt1">
        <button className="butt">View more</button>
      </div> */}
      <div className="home-head">
        <h3 className="aboutheading">
          IT Training, Communication Skill Development, Career Guidance, Campus
          Recruitment Training (CRT)
        </h3>
        <br />

        <div className="course-head">{/* <h3>Courses We Offer</h3> */}</div>
        <div className="course">
          <Card style={{ width: "320px" }}>
            <div className="car">
              <Card.Img variant="top" src={card1} />
              <Card.Body>
                <Card.Title id="card-title">IT Training</Card.Title>
                <Card.Text>
                  <ul className="card-text1">
                    <li>Programming Language</li>
                    <li>Python & Data Analytics</li>
                    <li>Web Design / Development</li>
                    <li>JAVA/C/C++/Php</li>
                    <li>HTML/CSS/JS/TS/Bootstrap</li>
                    <li>Mini Projects</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </div>
          </Card>

          <Card style={{ width: "320px" }}>
            <div className="car">
              <Card.Img variant="top" src={card2} />
              <Card.Body>
                <Card.Title id="card-title">
                  Communication Skill Development
                </Card.Title>
                <Card.Text>
                  <ul className="card-text1">
                    <li>English Spoken/Written</li>
                    <li>Soft Skills</li>
                    <li>Resume Building & Presentation</li>
                    <li>Career Guidance and Discussion</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </div>
          </Card>

          <Card style={{ width: "320px" }}>
            <div className="car">
              <Card.Img variant="top" src={card3} />
              <Card.Body>
                <Card.Title id="card-title">
                  Campus Recruitment Training (CRT)
                </Card.Title>
                <Card.Text>
                  <ul className="card-text1">
                    <li>Technical Training</li>
                    <li>Aptitude</li>
                    <li>Verbal and Non-Verbal</li>
                    <li>Math and Logical Reasoning</li>
                    <li>Problem Solving</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </div>
          </Card>
        </div>
      </div>

      <div className="home-head">
        <div className="container">
          <h1 style={{ textAlign: "center", fontSize: "2rem" }}>
            Specialized Job Oriented Programs
          </h1>
          <div className="home-cont">
            <div className="home-course">
              <div>
                <img src="./Image/Python/python.jpg" alt="" />
              </div>
              <div className="home-imgtext">
                <h3>Python FullStack Developer</h3>
                <p className="home-ptext">
                  Full stack technology refers to the entire depth of a computer
                  system application, and full stack developers straddle two
                  separate web development domains: the front end and the back
                  end. The front end includes everything that a client, or site
                  viewer, can see and interact with
                </p>
              </div>
            </div>
            <div className="homw-supimg">
              <span className="course-eximg1">
                <img
                  className="course-eximg"
                  src="./Image/Python/django.jpg"
                  alt=""
                />
              </span>
              <span className="course-eximg1">
                <img
                  className="course-eximg"
                  src="./Image/Python/flask.jpg"
                  alt=""
                />
              </span>
              <span className="course-eximg1">
                <img
                  className="course-eximg"
                  src="./Image/Python/sqllite.jpg"
                  alt=""
                />
              </span>
            </div>
          </div>

          <div className="home-cont">
            <div className="home-course">
              <div>
                <img src="./Image/Java/java.jpg" alt="" />
              </div>
              <div className="home-imgtext">
                <h3>JAVA FullStack Developer</h3>
                <p className="home-ptext">
                  Java full-stack programmer is a developer who is trained in
                  the Java programming language and technologies and knows both
                  front-end and back-end development.
                </p>
                <p className="home-ptext">
                  Has excellent knowledge of HTML and CSS, Javascript, Git and
                  GitHub, Web Architecture, Backend Languages, Spring
                  Frameworks.
                </p>
              </div>
            </div>
            <div className="homw-supimg">
              <span className="course-eximg1">
                <img
                  className="course-eximg"
                  src="./Image/Java/jdk.jpg"
                  alt=""
                />
              </span>
              <span className="course-eximg1">
                <img
                  className="course-eximg"
                  src="./Image/Java/spring.jpg"
                  alt=""
                />
              </span>
              {/* <span className="course-eximg1">
          <img className="course-eximg" src="./Image/Java/sqllite.jpg" alt="" />
        </span> */}
            </div>
          </div>

          <div className="home-cont">
            <div className="home-course">
              <img className="home-img1" src="./Image/JS/js.jpg" alt="" />

              <div className="home-imgtext">
                <h3>Web Development / Designing</h3>
                <p className="home-ptext web">
                  Web design encompasses many different skills and disciplines
                  in the production and maintenance of websites. The different
                  areas of web design include web graphic design; user interface
                  design; authoring, including standardised code and proprietary
                  software; user experience design; and search engine
                  optimization.
                </p>
              </div>
            </div>
            <br />
            <br />
            <div className="home-course">
              <div>
                <img className="homeimg1" src="./Image/JS/js1.jpg" alt="" />
              </div>
              <div className="home-imgtext">
                <p className="home-ptext web1">
                  Web development is the work involved in developing a website
                  for the Internet or an intranet. Web development can range
                  from developing a simple single static page of plain text to
                  complex web applications, electronic businesses, and social
                  network services.
                </p>
              </div>
            </div>
            <div className="homw-supimg">
              <span className="course-eximg1">
                <img className="course-eximg" src="./Image/JS/tys.jpg" alt="" />
              </span>
              <span className="course-eximg1">
                <img
                  className="course-eximg"
                  src="./Image/JS/angular.jpg"
                  alt=""
                />
              </span>
              <span className="course-eximg1">
                <img
                  className="course-eximg"
                  src="./Image/JS/html.jpg"
                  alt=""
                />
              </span>
            </div>
          </div>

          <div className="home-cont">
            <div className="home-course">
              <div>
                <img src="./Image/SQL/sql.jpg" alt="" />
              </div>
              <div className="home-imgtext">
                <h3>RDBMS / SQL</h3>
                <p className="home-ptext">
                  RDBMS is a program used to maintain a relational database.
                  RDBMS is the basis for all modern database systems such as
                  MySQL, Microsoft SQL Server, Oracle, and Microsoft Access.
                  RDBMS uses SQL queries to access the data in the database.
                </p>
                <p className="home-ptext">
                  SQL career paths include SQL Server Database Administration
                  and Development, Business intelligence professionals, Data
                  science, and engineering will come in successful SQL career
                  path,
                </p>
              </div>
            </div>
            <div className="homw-supimg">
              <span className="course-eximg1">
                <img
                  className="course-eximg"
                  src="./Image/SQL/oracle.jpg"
                  alt=""
                />
              </span>
              <span className="course-eximg1">
                <img
                  className="course-eximg"
                  src="./Image/SQL/sql.jpg"
                  alt=""
                />
              </span>
              {/* <span className="course-eximg1">
          <img className="course-eximg" src="./Image/SQL/sqllite.jpg" alt="" />
        </span> */}
            </div>
          </div>

          <div className="home-cont">
            <div className="home-course">
              <div>
                <img className="homeimg" src="./Image/XL/xl.jpg" alt="" />
              </div>
              <div className="home-imgtext">
                <h3>Advanced EXCEL</h3>
                <p className="home-ptext">
                  Advanced Excel skills include the ability to produce graphs
                  and tables, use spreadsheets efficiently, and perform
                  calculations and automation to process large volumes of data.
                  With the rise of big data and data analytics, advanced Excel
                  skills are a real asset both during the job hunt and everyday
                  work tasks.
                </p>
              </div>
            </div>
            {/* <div>
        <span className="course-eximg1">
          <img className="course-eximg" src="./Image/Python/django.jpg" alt="" />
        </span>
        <span className="course-eximg1">
          <img className="course-eximg" src="./Image/Python/flask.jpg" alt="" />
        </span>
        <span className="course-eximg1">
          <img className="course-eximg" src="./Image/Python/sqllite.jpg" alt="" />
        </span>
        </div> */}
          </div>

          <div className="home-cont">
            <div className="home-course">
              <div>
                <img
                  className="homeimg"
                  src="./Image/Powerbi/powerbi.jpg"
                  alt=""
                />
              </div>
              <div className="home-imgtext">
                <h3>Power BI</h3>
                <p className="home-ptext">
                  Power BI is an interactive data visualization software product
                  developed by Microsoft with a primary focus on business
                  intelligence. It is part of the Microsoft Power Platform.
                </p>
                <p className="home-ptext">
                  Create a data-driven culture with business intelligence for
                  all.
                </p>
              </div>
            </div>
            {/* <div>
        <span className="course-eximg1">
          <img className="course-eximg" src="./Image/Python/django.jpg" alt="" />
        </span>
        <span className="course-eximg1">
          <img className="course-eximg" src="./Image/Python/flask.jpg" alt="" />
        </span>
        <span className="course-eximg1">
          <img className="course-eximg" src="./Image/Python/sqllite.jpg" alt="" />
        </span>
        </div> */}
          </div>

          <div className="home-cont">
            <div className="home-course">
              <div>
                <img
                  className="homeimg"
                  src="./Image/devops/devops.jpg"
                  alt=""
                />
              </div>
              <div className="home-imgtext">
                <h3>DevOps</h3>
                <p className="home-ptext">
                  DevOps is a methodology in the software development and IT
                  industry. Used as a set of practices and tools, DevOps
                  integrates and automates the work of software development and
                  IT operations as a means for improving and shortening the
                  systems development life cycle.
                </p>
              </div>
            </div>
            {/* <div>
        <span className="course-eximg1">
          <img className="course-eximg" src="./Image/Python/django.jpg" alt="" />
        </span>
        <span className="course-eximg1">
          <img className="course-eximg" src="./Image/Python/flask.jpg" alt="" />
        </span>
        <span className="course-eximg1">
          <img className="course-eximg" src="./Image/Python/sqllite.jpg" alt="" />
        </span>
        </div> */}
          </div>

          <div className="home-cont">
            <div className="home-course">
              <div>
                <img className="homeimg" src="./Image/cloud/cloud.jpg" alt="" />
              </div>
              <div className="home-imgtext">
                <h3>Cloud Computing (AWS, Azure)</h3>
                <p className="home-ptext">
                  Cloud computi40pxng is the on-demand availability of computer
                  system resources, especially data storage and computing power,
                  without direct active management by the user. Large clouds
                  often have functions distributed over multiple locations, each
                  of which is a data center.
                </p>
              </div>
            </div>
            {/* <div>
        <span className="course-eximg1">
          <img className="course-eximg" src="./Image/Python/django.jpg" alt="" />
        </span>
        <span className="course-eximg1">
          <img className="course-eximg" src="./Image/Python/flask.jpg" alt="" />
        </span>
        <span className="course-eximg1">
          <img className="course-eximg" src="./Image/Python/sqllite.jpg" alt="" />
        </span>
        </div> */}
          </div>

          <div className="home-cont">
            <div className="home-course">
              <div>
                <img className="home-c" src="./Image/C++/c++.jpg" alt="" />
              </div>
              <div className="home-imgtext">
                <h3>C++</h3>
                <p className="home-ptext">
                  C++ is a high-level general-purpose programming language
                  created by Danish computer scientist Bjarne Stroustrup as an
                  extension of the C programming language, or "C with Classes".
                </p>
              </div>
            </div>
            {/* <div>
        <span className="course-eximg1">
          <img className="course-eximg" src="./Image/Python/django.jpg" alt="" />
        </span>
        <span className="course-eximg1">
          <img className="course-eximg" src="./Image/Python/flask.jpg" alt="" />
        </span>
        <span className="course-eximg1">
          <img className="course-eximg" src="./Image/Python/sqllite.jpg" alt="" />
        </span>
        </div> */}
          </div>

          <div className="home-cont">
            <div className="home-course">
              <div>
                <img className="home-c" src="./Image/C/c.jpg" alt="" />
              </div>
              <div className="home-imgtext">
                <h1>C</h1>
                <p className="home-ptext">
                  C is a general-purpose computer programming language. It was
                  created in the 1970s by Dennis Ritchie, and remains very
                  widely used and influential. By design, C's features cleanly
                  reflect the capabilities of the targeted CPUs.
                </p>
              </div>
            </div>
            {/* <div>
        <span className="course-eximg1">
          <img className="course-eximg" src="./Image/Python/django.jpg" alt="" />
        </span>
        <span className="course-eximg1">
          <img className="course-eximg" src="./Image/Python/flask.jpg" alt="" />
        </span>
        <span className="course-eximg1">
          <img className="course-eximg" src="./Image/Python/sqllite.jpg" alt="" />
        </span>
        </div> */}
          </div>
        </div>
      </div>

      <Bottom />
    </>
  );
};

export default Home;
