import React from "react";
import "../Aboutus/Aboutus.css";
import Card from "react-bootstrap/Card";
import abtimg from "./images/istockphoto-1047549798-612x612.jpg";
import abtimg1 from "./images/istockphoto-1141304181-612x612.jpg";
import abtimg2 from "./images/istockphoto-1409835273-612x612.jpg";
import Header from "../Header";
import Bottom from "../Bottom/Bottom";
import About from "../images/about.webp";
import OwlCarousel from "react-owl-carousel";

import img5 from "./images/A.png";
import img6 from "./images/ayl.png";
import img7 from "./images/boot.png";
import img8 from "./images/c.png";
import img9 from "./images/c++.png";
import img10 from "./images/cp.png";
import img11 from "./images/django.png";
import img12 from "./images/flask.png";
import img13 from "./images/html.png";
import img14 from "./images/java.png";
import img15 from "./images/jq.png";
import img16 from "./images/mysql.png";
import img17 from "./images/powerbi.jpg";
import img18 from "./images/py.jpg";
import Whatsapp from "../Whatsapp/Whatsapp";
import card1 from "./images/card1.jpg";
import card2 from "./images/card2.jpg";
import card3 from "./images/card3.jpg";
import {CiFaceSmile} from 'react-icons/ci'

const Aboutus = () => {
  //Owl Carousel Settings
  // const options = {
  //   loop: true,
  //   center: true,
  //   items: 3,
  //   margin: 0,
  //   autoplay: true,
  //   dots: true,
  //   autoplayTimeout: 8500,
  //   smartSpeed: 450,
  //   nav: false,
  //   responsive: {
  //     0: {
  //       items: 3,
  //     },
  //     600: {
  //       items: 5,
  //     },
  //     1000: {
  //       items: 7,
  //     },
  //   },
  // };
  return (
    <>
      <Header />
			<br />
			<br />
			<br />

      <Whatsapp />

      {/* <div style={{backgroundImage:"url('./ban-about.jpeg')", width:'100%', height:'450px', backgroundPosition:"-50px -50px", backgroundSize:"cover"}}>

      </div> */}

<img  class="page-header ltx-parallax" src="./contactimg2.jpg" alt="" style={{  height:"400px", width:'100%' }}/>


      {/* <img
        className="aboutimg"
        src="./ban-about.jpeg"
        alt=""
        style={{ width: "100%", height: "400px", marginTop: "5%" }}
      /> */}


<div className="home-hea">
      <div className="about-nav">
        {/* <span className="about-nav1">Home</span> {">>"} <span>About us</span> */}
      </div>
      <div className="heading">
        <h1>About us</h1>
      </div>

      <section className="aboutus">
        <div>
          <h3 className="about-heading">
            SkillFirst.in is a unique platform which is not just yet another
            computer or IT Training company but a place where each individual
            can discuss, decide and take action that suits his/her personal and
            realistic career goal.
          </h3>
          {/* <br /> */}
          <h3 className="about-heading">
            There are many service providers who are either too big or too basic
            to cater to your unique requirements. This is where we come in.
          </h3>
          {/* <br /> */}
          <h3 className="about-heading">
            Career planning should be based on elaborate understanding of
            student’s background and more so what he/she is passionate about.
          </h3>
          {/* <br /> */}
          <h3 className="about-heading">
            Our trainers come with industry experience as well as personal
            lessons learnt after facing various situations in the job markets
            and the HR domain practices. Hence, it is natural to be able to help
            the students take appropriate and informed decisions.
          </h3>
          {/* <br /> */}
          <h3 className="about-heading">
          Feel free to contact us for further discussion or suggestion. Welcome aboard! <CiFaceSmile/>
          </h3>
          <br />
          <br />
        </div>

        
        <div className="row row1">
          <img className="aboutimg" src={abtimg} alt="aboutimg" />
          <div className="about-text">
            <h4 className="about-h4">For Freshers</h4>
            <p className="aboutus-p-">
              Skillfirst.in is a IT training and development company focused on
              creating a manageable platform for any student to plan, discuss
              and take action for creating a career and learning path best
              suited to him or her.
            </p>
            <p className="aboutus-p-">
              At skillfirst.in we help students identify their strengths and
              hone relevant skills and knowledge in order to become employable
              as soon as possible.
            </p>
          </div>
          </div>
        <br />

        <div className="row row1">
          <img className="aboutimg" src={abtimg1} alt="aboutimg" />
          <div className="about-text">
            <h4 className="about-h4">
              For experienced professionals or job seekers
            </h4>
            <p className="aboutus-p-">
              We help the experienced professionals to quickly build the next
              set of skills and apply for newer roles thereby avoiding loss of
              interest in the current profile or loss of employment.
            </p>
          </div>
        </div>
        <br />

        <div className="row row1">
          <img className="aboutimg" src={abtimg2} alt="aboutimg" />
          <div className="about-text">
            <h4 className="about-h4">School students</h4>
            <p className="aboutus-p-">
              Many advanced topics are introduced right at the high-school or
              secondary school level. Students can join online IT training
              sessions and cover the school syllabus and also go that extra mile
              to become proficient in the software subject such as C++, JAVA,
              SQL etc.
            </p>
          </div>
        </div>
        <br />

        {/* <div className="course-head">
        <h3>Courses We Offer</h3>
      </div>
      <div className="course">
        <Card style={{ width: "300px", height: "450px" }}>
          <Card.Img variant="top" src={card1} />
          <Card.Body>
            <Card.Title>Programming Language</Card.Title>
            <Card.Text>
              <ul className="card-text1">
                <li>Database Management</li>
                <li>Python and Data Analytics</li>
                <li>Web Design/ Development</li>
                <li>JAVA/C/C++</li>
                <li>HTML CSS Bootstrap</li>
              </ul>
            </Card.Text>
          </Card.Body>
        </Card>

        <Card style={{ width: "300px", height: "450px" }}>
          <Card.Img variant="top" src={card2} />
          <Card.Body>
            <Card.Title>Communication Skill Development</Card.Title>
            <Card.Text>
              <ul className="card-text1">
                <li>Spoken and Written English</li>
                <li>Soft Skill Training</li>
                <li>Resume Writing and Presentation Skills</li>
                <li>Career Guidence and Discussion</li>
              </ul>
            </Card.Text>
          </Card.Body>
        </Card>

        <Card style={{ width: "300px", height: "450px" }}>
          <Card.Img variant="top" src={card3} />
          <Card.Body>
            <Card.Title>Campus Recruitment Training</Card.Title>
            <Card.Text>
              <ul className="card-text1">
                <li>Technical Training</li>
                <li>Aptitude</li>
                <li>Verbal and Non-Verbal Communication</li>
              </ul>
            </Card.Text>
          </Card.Body>
        </Card>
      </div> */}
      </section>

      {/* <div className="heading">
        <h1>Our Courses</h1>
      </div>

      <div className="card1">
        <div className="container">
        <div className="card-header1"> C-Programming</div>
        <div className="card-body1"><img src={img10}/></div>
        </div>
      </div> */}

      {/* <OwlCarousel
        className="owl-theme"
        loop
        margin={10}
        autoPlay={true}
        item={6}
        {...options}
        nav
      >
        <div class="item">
          <img src={img5} alt="" />
        </div>
        <div class="item">
          <img src={img6} alt="" />
        </div>
        <div class="item">
          <img src={img7} alt="" />
        </div>
        <div class="item">
          <img src={img8} alt="" />
        </div>
        <div class="item">
          <img src={img9} alt="" />
        </div>
        <div class="item">
          <img src={img10} alt="" />
        </div>
        <div class="item">
          <img src={img11} alt="" />
        </div>
        <div class="item">
          <img src={img12} alt="" />
        </div>
        <div class="item">
          <img src={img13} alt="" />
        </div>
        <div class="item">
          <img src={img14} alt="" />
        </div>
        <div class="item">
          <img src={img15} alt="" />
        </div>
        <div class="item">
          <img src={img16} alt="" />
        </div>
        <div class="item">
          <img src={img17} alt="" />
        </div>
        <div class="item">
          <img src={img18} alt="" />
        </div>
      </OwlCarousel> */}

</div>

      <Bottom />
    </>
  );
};

export default Aboutus;
