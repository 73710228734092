import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Container } from "react-bootstrap";
import "../Component/Header.css";
import { Link } from "react-router-dom";
import {ImPhone} from 'react-icons/im'
// import logo from "./images/Final-logo.png";

function Header() {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" style={{}} id="nav-bar">
        <Container>
          <Navbar.Brand>
            <img className="logo" src="./Final-logo-1.jpg" alt="" />
            <marquee behavior="" direction="" id="marque">
              {/* Skillfirst.in is a IT Training and Development Company catering to
              needs of individuals at various levels of their careers. */}
              info@skillfirst.in skillfisrt.in@gmail.com  Mob and Whatsapp: 7337796813
            </marquee>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="sasa">
              <Nav.Link href="/home">Home</Nav.Link>
              <Nav.Link href="/about">About Us</Nav.Link>
              <Nav.Link href="/contact">Contact Us</Nav.Link>
              <br />
              <Nav.Link href="/contact"><ImPhone/> 7337796813</Nav.Link>
              
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
