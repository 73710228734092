import React from 'react'
import {SiWhatsapp} from "react-icons/si"
import './whatsapp.css'

function Whatsapp() {
  return (
   <>
   <div className=''>
    <div className='whats-app'>
       <a href='https://wa.aisensy.com/tfoGUG'> <SiWhatsapp style={{color:"white",fontSize:"40px", marginBottom:'2px'}}/>
       </a> </div>
   </div>
   </>
  )
}

export default Whatsapp